import { GaspardeskApiUrl } from '../../../constants';

export class GaspardeskApiClient {
  static async signupCustomer(data: Record<string, unknown>) {
    return postToApi('customers/signup/start/', data);
  }
}

/************************************************************************************************
 * Helpers
 ************************************************************************************************/

async function postToApi(endpoint: string, data: Record<string, unknown>) {
  // const query = qs.stringify({ fields, populate, sort, pagination }, { encodeValuesOnly: true });
  const response = await fetch(`${GaspardeskApiUrl}/${endpoint}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json, text/plain, */*',
    },
  });
  if (response.status >= 400) {
    throw new Error(`Failed to fetch data from ${GaspardeskApiUrl}/${endpoint}`);
  }
  const payload = await response.json();
  return payload;
}
