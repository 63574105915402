import { def } from '@gaspardesk/utils';
import classnames from 'classnames';
import React, { useCallback, useEffect, useMemo } from 'react';

import { CDNAssets } from '../../CDN';
import FormBackdrop from '../../components/backdrops/FormBackdrop';
import PrimaryLink from '../../components/buttons/PrimaryLink';
import Segmented from '../../components/controls/Segmented';
import ContactForm, { ContactUsMode } from '../../components/forms/ContactForm';
import { GaspardeskApiClient } from '../../lib/integrations/api/GaspardeskApiClient';
import { StrapiApiClient } from '../../lib/integrations/strapi/StrapiApiClient';
import pageRoutes from '../../router/pageRoutes';

export const CONTACT_FORM_ID = 'contact-form';

const contactUsModeDetails = {
  [ContactUsMode.Connect]: {
    asteriskClassName: 'text-brandGreen',
    formTitle: 'Connect with us!',
    formDescription:
      'Gaspar AI customers achieve 80% less manual work while reducing helpdesk ticket cost to $1. Talk with a Sales expert and discover how!',
    submitButtonText: 'Reach out to us',
    submitButtonColor: 'green',
  },
  [ContactUsMode.DemoRequest]: {
    asteriskClassName: 'text-brandGreen',
    formTitle: 'Request a Demo',
    formDescription:
      'Learn how you can automate tasks and workflows to reduce manual work by 80%, automatically resolve 40% of issues, and optimize your knowledge management',
    submitButtonText: 'Request a Demo',
    submitButtonColor: 'green',
  },
  [ContactUsMode.FreeTrial]: {
    asteriskClassName: 'text-purple',
    formTitle: '21-day Free Trial',
    formDescription:
      'Get started today for free - no credit card needed! Your free trial includes unlimited access for 21 days, with an AI chatbot on Slack and MS Teams, workflow automation and Proactive Insights. Deployment in just 30 minutes!',
    submitButtonText: 'Try free for 21 days',
    submitButtonColor: 'blue',
  },
};

type ContactUsWidgetProps = {
  showAllModes: boolean;
  defaultMode: ContactUsMode;
};
const ContactUsWidget: React.FC<ContactUsWidgetProps> = React.memo((props) => {
  const { defaultMode, showAllModes } = props;

  const [showSuccessForm, setShowSuccessForm] = React.useState<boolean>(false);
  const [selectedMode, setSelectedMode] = React.useState<string>(ContactUsMode.FreeTrial);

  // Reset the selected option when the default option changes
  useEffect(() => setSelectedMode(defaultMode), [defaultMode]);

  // Calculate the details based on the selected option
  const { asteriskClassName, formTitle, formDescription, submitButtonText, submitButtonColor } = useMemo(
    () => contactUsModeDetails[selectedMode],
    [selectedMode],
  );

  // Prepare and send the form data to the Strapi API
  const handleFormSubmit = useCallback(
    async (formData) => {
      const postData = {
        ['by_checking_this_box,_i_agree_to_receive_company_news_and_updates._learn_more_in_the_privacy_policy.']:
          formData.newsletterCheckbox,
        full_name: formData.name,
        company: formData.company,
        org_name: formData.company, // To provide support for the GasparDesk API
        email: formData.email,
        description: formData.description,
        phone_number: formData.phoneNumber,
        phone: formData.phoneNumber, // To provide support for the GasparDesk API
        job_title: formData.jobTitle,
        domain: formData.domain,
      };
      if (selectedMode === ContactUsMode.FreeTrial) {
        // Submit the form to the Gaspardesk API
        GaspardeskApiClient.signupCustomer(postData)
          .then(() => {
            setShowSuccessForm(true);
          })
          .catch(() => {
            setShowSuccessForm(false);
          });
      }
      if (selectedMode === ContactUsMode.Connect) {
        // Submit the form to the Strapi API
        StrapiApiClient.postContactUsRequest(postData).then((response) => {
          if (response.ok) {
            setShowSuccessForm(true);
          }
        });
        // Track LinkedIn event conversion
        if (def((window as any)?.lintrk)) {
          (window as any).lintrk('track', { conversion_id: 14267977 });
        }
      }
      if (selectedMode === ContactUsMode.DemoRequest) {
        // Submit the form to the Strapi API
        StrapiApiClient.postDemoRequest(postData).then((response) => {
          if (response.ok) {
            setShowSuccessForm(true);
          }
        });
        // Track LinkedIn event conversion
        if (def((window as any)?.lintrk)) {
          (window as any).lintrk('track', { conversion_id: 14267953 });
        }
      }
    },
    [selectedMode],
  );
  // Determine the backdrop image based on the selected option
  const backdropImageUrl = useMemo(
    () => (selectedMode === ContactUsMode.FreeTrial ? CDNAssets.getUrl('gradient-disc-blue') : CDNAssets.getUrl('gradient-disc-green')),
    [selectedMode],
  );
  return (
    <div className="mx-auto max-w-[1000px] bg-black" style={{ boxShadow: '-1px -15px 25px 15px rgba(0,0,0,0.75)' }} id={CONTACT_FORM_ID}>
      <FormBackdrop
        className={classnames('rounded-[40px] border-1 border-pureWhite/10 transition-opacity duration-800 ease-in-out ')}
        imageUrl={backdropImageUrl}
      >
        {showSuccessForm && (
          <div className="py-4 px-6 md:py-10 md:px-20 bg-black/10">
            <div className="text-center flex flex-col gap-[10px] justify-items-center my-[50px]">
              <h2>Thank you for getting in touch! </h2>
              <p className="text-xl">A member of our team will get back in touch with you within 1 business day. </p>
              <div className="mt-[40px]">
                <PrimaryLink className="border-1 text-pureWhite rounded-full px-4 py-2 " href={pageRoutes.Index}>
                  Go to home page
                </PrimaryLink>
              </div>
            </div>
          </div>
        )}
        {!showSuccessForm && (
          <div className="py-5 px-2 md:py-10 md:px-20 bg-black/10">
            <div className="text-center flex flex-col justify-items-center">
              {showAllModes && (
                <div className="self-center">
                  <Segmented
                    buttonsClassName="py-3 px-10 md:py-3 md:px-16"
                    nonSelectedClassName="text-pureWhite bg-pureWhite/10"
                    selectedClassNames={{
                      [ContactUsMode.FreeTrial]: 'text-pureWhite bg-purple',
                      [ContactUsMode.DemoRequest]: 'text-black bg-brandGreen',
                      [ContactUsMode.Connect]: 'text-black bg-brandGreen',
                    }}
                    options={[ContactUsMode.FreeTrial, ContactUsMode.DemoRequest]}
                    selectedOption={selectedMode}
                    setSelectedOption={setSelectedMode}
                  />
                </div>
              )}
              <ContactForm
                asteriskClassName={asteriskClassName}
                formTitle={formTitle}
                formDescription={formDescription}
                submitButtonText={submitButtonText}
                submitButtonColor={submitButtonColor}
                mode={selectedMode as ContactUsMode}
                onSubmit={handleFormSubmit}
              />
            </div>
          </div>
        )}
      </FormBackdrop>
    </div>
  );
});
ContactUsWidget.displayName = 'ContactUsWidget';
export default ContactUsWidget;
